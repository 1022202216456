<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card" :labelWidth="90">
        <div slot="header" class="clearfix" style="height: auto">
          <div class="btn-wrap">
            <el-button
              size="small"
              @click="submit"
              :loading="loading"
              class="confirm"
              type="primary">
              提交申请
            </el-button>
          </div>

          <span>基本信息</span>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="结算方式">
            <ykc-radio
              :data="[
                { id: 1, name: '一口价' },
                { id: 2, name: '尖峰平谷' },
              ]"
              v-model="meterSettleWay"></ykc-radio>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="站点">
            <span>{{ dealData(formData.stationName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="订单⾦额是否给物业" :labelWidth="120">
            <span>{{ formData.orderTo === 1 ? '是' : '否' }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="取电方式">
            <span>{{ dealData(formData.eleWay) }}</span>
          </ykc-detail-item-plus>
        </div>
        <ykc-form
          v-show="meterSettleWay === 1"
          ref="ruleForm1"
          class="YkcForms1"
          :model="ruleForm1"
          :rules="rules1"
          labelPosition="right">
          <ykc-form-item label="本次抄表度数(电表实际数值)" prop="localMeterKwh">
            <ykc-input
              v-model="ruleForm1.localMeterKwh"
              @blur="calcCurrent('MeterKwh')"
              maxlength="30"
              placeholder="请输入本次抄表度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次对账电量">
            <ykc-input
              v-model="ruleForm1.MeterKwh"
              disabled
              maxlength="30"
              placeholder="请输入本次对账电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表日期" prop="lastTime">
            <ykc-date-picker
              type="date"
              :limitDateRange="[lastTime, yesterday]"
              v-model="ruleForm1.lastTime"
              placeholder="请选择上次抄表日期"></ykc-date-picker>
          </ykc-form-item>
          <ykc-form-item label="上次抄表度数" prop="lastMeterKwh">
            <ykc-input
              v-model="ruleForm1.lastMeterKwh"
              maxlength="30"
              @blur="calcCurrent('MeterKwh')"
              placeholder="请输入上次抄表度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表日期" prop="endSettleDate">
            <ykc-date-picker
              v-model="ruleForm1.endSettleDate"
              type="date"
              :limitDateRange="[lastTime, yesterday]"
              placeholder="请输入本次抄表日期"></ykc-date-picker>
          </ykc-form-item>
          <ykc-form-item label=""></ykc-form-item>
          <ykc-form-item
            label="附件(电量抄表照片)"
            prop="settleAttachmentDTO"
            class="imgUpload"
            style="width: 100%">
            <ykc-upload
              :size="10"
              :limit="15"
              type="img"
              ref="settleAttachmentDTO1"
              :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
              @handleChange="(file, fileList, isCheck) => uploadAd(file, fileList, isCheck)"
              @handleRemove="handleRemove"></ykc-upload>
          </ykc-form-item>
          <!-- <ykc-form-item label=""></ykc-form-item> -->
          <ykc-form-item label="备注">
            <ykc-input
              v-model="ruleForm1.remark"
              type="textarea"
              placeholder="备注"
              maxlength="500"></ykc-input>
          </ykc-form-item>
        </ykc-form>

        <ykc-form
          v-show="meterSettleWay === 2"
          ref="ruleForm2"
          :model="ruleForm2"
          :rules="rules2"
          labelPosition="right"
          class="YkcForms2">
          <ykc-form-item label="本次抄表尖时度数(电表实际数值)" prop="localTip">
            <ykc-input
              v-model="ruleForm2.localTip"
              maxlength="30"
              @blur="calcCurrent('Tip')"
              placeholder="请选择本次抄表尖时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表尖时度数" prop="lastTip">
            <ykc-input
              v-model="ruleForm2.lastTip"
              maxlength="30"
              @blur="calcCurrent('Tip')"
              placeholder="请输入上次抄表尖时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次尖时对账电量">
            <ykc-input
              v-model="ruleForm2.Tip"
              maxlength="30"
              disabled
              placeholder="请输入本次对账电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表峰时度数(电表实际数值)" prop="localPeak">
            <ykc-input
              v-model="ruleForm2.localPeak"
              @blur="calcCurrent('Peak')"
              maxlength="30"
              placeholder="请输入本次抄表峰时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表峰时度数" prop="lastPeak">
            <ykc-input
              v-model="ruleForm2.lastPeak"
              maxlength="30"
              @blur="calcCurrent('Peak')"
              placeholder="请输入上次抄表峰时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次峰时对账电量">
            <ykc-input
              v-model="ruleForm2.Peak"
              maxlength="30"
              disabled
              placeholder="请输入本次峰时对账电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表平时度数(电表实际数值)" prop="localFlat">
            <ykc-input
              v-model="ruleForm2.localFlat"
              @blur="calcCurrent('Flat')"
              maxlength="30"
              placeholder="请输入本次抄表平时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表平时度数" prop="lastFlat">
            <ykc-input
              v-model="ruleForm2.lastFlat"
              maxlength="30"
              @blur="calcCurrent('Flat')"
              placeholder="请输入上次抄表平时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次平时对账电量">
            <ykc-input
              v-model="ruleForm2.Flat"
              maxlength="30"
              disabled
              placeholder="请输入本次平时对账电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表谷时度数(电表实际数值)" prop="localValley">
            <ykc-input
              v-model="ruleForm2.localValley"
              @blur="calcCurrent('Valley')"
              maxlength="30"
              placeholder="请输入本次抄表谷时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表谷时度数" prop="lastValley">
            <ykc-input
              v-model="ruleForm2.lastValley"
              maxlength="30"
              @blur="calcCurrent('Valley')"
              placeholder="请输入上次抄表谷时度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次谷时对账电量">
            <ykc-input
              v-model="ruleForm2.Valley"
              maxlength="30"
              disabled
              placeholder="请输入本次谷时对账电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表总度数">
            <ykc-input
              v-model="ruleForm2.localTotal"
              maxlength="30"
              disabled
              placeholder="请输入本次抄表总度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="上次抄表总度数">
            <ykc-input
              v-model="ruleForm2.lastTotal"
              disabled
              maxlength="30"
              placeholder="请输入上次抄表总度数"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次对账总电量">
            <ykc-input
              v-model="ruleForm2.Total"
              disabled
              maxlength="30"
              placeholder="请输入本次对账总电量"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="本次抄表日期" prop="endSettleDate">
            <ykc-date-picker
              v-model="ruleForm2.endSettleDate"
              type="date"
              :limitDateRange="[lastTime, yesterday]"
              placeholder="请选择本次抄表日期"></ykc-date-picker>
          </ykc-form-item>
          <ykc-form-item label="上次抄表日期" prop="lastTime">
            <ykc-date-picker
              type="date"
              :limitDateRange="[lastTime, yesterday]"
              v-model="ruleForm2.lastTime"
              placeholder="请选择上次抄表日期"></ykc-date-picker>
          </ykc-form-item>

          <ykc-form-item label=""></ykc-form-item>
          <ykc-form-item
            label="附件(电量抄表照片)"
            prop="settleAttachmentDTO"
            class="imgUpload"
            style="width: 100%">
            <ykc-upload
              :size="10"
              :limit="15"
              type="img"
              ref="settleAttachmentDTO2"
              :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
              @handleChange="(file, fileList, isCheck) => uploadAd(file, fileList, isCheck)"
              @handleRemove="handleRemove"></ykc-upload>
          </ykc-form-item>
          <ykc-form-item label="备注" style="width: 66.66%">
            <ykc-input
              v-model="ruleForm2.remark"
              type="textarea"
              placeholder="备注"
              maxlength="500"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>
<script>
  import dayjs from 'dayjs';
  import { toFixed } from '@/utils';
  import { propertySettleManage, uploadUtils } from '@/service/apis';

  export default {
    name: 'settle',
    components: {},
    data() {
      const regex = /^\d+(\.\d{1,2})?$/;
      return {
        meterSettleWay: 1,
        loading: false,
        stationId: '',
        formData: {
          name: '',
          time: [],
        },
        lastTime: '',
        yesterday: dayjs().subtract(1, 'day').toDate(),
        ruleForm1: {
          localMeterKwh: '',
          MeterKwh: '',
          endSettleDate: '',
          lastMeterKwh: 0,
          lastTime: '',
          settleAttachmentDTO: [],
          remark: '',
        },
        ruleForm2: {
          endSettleDate: '',
          localFlat: '',
          lastFlat: 0,
          Flat: '',
          localPeak: '',
          lastPeak: 0,
          Peak: '',
          localTip: '',
          lastTip: 0,
          Tip: '',
          localValley: '',
          lastValley: 0,
          Valley: '',
          settleAttachmentDTO: [],
          lastTime: '',
          remark: '',
          lastTotal: 0,
          localTotal: '',
          Total: '',
        },
        rules1: {
          localMeterKwh: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, val, callback) => {
                const value = Number(val || 0);
                if (!val) {
                  callback(new Error('请输入本次抄表度数'));
                }
                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value < this.ruleForm1.lastMeterKwh) {
                  callback(new Error('本次抄表度数不能小于上次抄表度数'));
                } else {
                  callback();
                }
              },
            },
          ],
          lastMeterKwh: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, val, callback) => {
                const value = Number(val || 0);
                if (!val) {
                  callback(new Error('请输入上次抄表度数'));
                }
                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value > this.ruleForm1.localMeterKwh) {
                  callback(new Error('上次抄表度数不能大于本次抄表度数'));
                } else {
                  callback();
                }
              },
            },
          ],
          endSettleDate: [
            { required: true, message: '请选择本次抄表日期', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                if (dayjs(val) < dayjs(this.ruleForm1.lastTime)) {
                  callback(new Error('本次抄表日期不能小于上次抄表日期'));
                } else {
                  callback();
                }
              },
              trigger: 'change',
            },
          ],
          lastTime: [
            { required: true, message: '请选择上次抄表日期', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                if (dayjs(val) > dayjs(this.ruleForm1.endSettleDate)) {
                  callback(new Error('上次抄表日期不能大于本次抄表日期'));
                } else {
                  callback();
                }
              },
              trigger: 'change',
            },
          ],
          settleAttachmentDTO: [{ required: true, message: '请上传附件', trigger: 'blur' }],
        },
        rules2: {
          localFlat: [
            { required: true, message: '请输入本次抄表平时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value < this.ruleForm2.lastFlat) {
                  callback(new Error('本次抄表度数不能小于上次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          lastFlat: [
            { required: true, message: '请输入上次抄表平时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value > this.ruleForm2.localFlat) {
                  callback(new Error('上次抄表度数不能大于本次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          localPeak: [
            { required: true, message: '请输入本次抄表峰时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value < this.ruleForm2.lastPeak) {
                  callback(new Error('本次抄表度数不能小于上次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          lastPeak: [
            { required: true, message: '请输入上次抄表峰时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value > this.ruleForm2.localPeak) {
                  callback(new Error('上次抄表度数不能大于本次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          localTip: [
            { required: true, message: '请输入本次抄表尖时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value < this.ruleForm2.lastTip) {
                  callback(new Error('本次抄表度数不能小于上次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          lastTip: [
            { required: true, message: '请输入上次抄表尖时度数', trigger: 'blur' },

            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value > this.ruleForm2.localTip) {
                  callback(new Error('上次抄表度数不能大于本次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          localValley: [
            { required: true, message: '请输入本次抄表谷时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value < this.ruleForm2.lastValley) {
                  callback(new Error('本次抄表度数不能小于上次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          lastValley: [
            { required: true, message: '请输入上次抄表谷时度数', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                const value = Number(val || 0);

                if (Number.isNaN(value)) {
                  callback(new Error('只能输入数字'));
                }
                if (!regex.test(val)) {
                  callback(new Error('最多两位小数'));
                }
                if (value > this.ruleForm2.localValley) {
                  callback(new Error('上次抄表度数不能大于本次抄表度数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          endSettleDate: [
            { required: true, message: '请选择本次抄表日期', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                if (dayjs(val) < dayjs(this.ruleForm2.lastTime)) {
                  callback(new Error('本次抄表日期不能小于上次抄表日期'));
                } else {
                  callback();
                }
              },
              trigger: 'change',
            },
          ],
          lastTime: [
            { required: true, message: '请选择上次抄表日期', trigger: 'blur' },
            {
              validator: (rule, val, callback) => {
                if (dayjs(val) > dayjs(this.ruleForm2.endSettleDate)) {
                  callback(new Error('上次抄表日期不能大于本次抄表日期'));
                } else {
                  callback();
                }
              },
              trigger: 'change',
            },
          ],
          settleAttachmentDTO: [{ required: true, message: '请上传附件', trigger: 'blur' }],
        },
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      const { stationId } = this.$route.query;

      this.stationId = stationId;
      this.queryDetail();
    },
    methods: {
      dealData(value) {
        return value === null ? '——' : value;
      },
      queryDetail() {
        // 编辑回显
        if (this.stationId) {
          propertySettleManage
            .getSettleInfo({ stationId: this.stationId })
            .then(res => {
              const { meterSettleWay, lastMeterKwh, lastTime } = res.propertyMeterSettleInfo;
              this.meterSettleWay = meterSettleWay || 1;
              this.lastTime = lastTime;
              this.ruleForm1 = {
                ...this.ruleForm1,
                lastMeterKwh,
                lastTime: lastTime ? dayjs(lastTime).format('YYYY-MM-DD') : '',
              };
              const { settlePeaksValley } = res.propertyMeterSettleInfo;
              this.ruleForm2 = {
                ...this.ruleForm2,
                lastFlat: settlePeaksValley?.lastFlat || 0,
                lastPeak: settlePeaksValley?.lastPeak || 0,
                lastTip: settlePeaksValley?.lastTip || 0,
                lastValley: settlePeaksValley?.lastValley || 0,
                lastTime: lastTime ? dayjs(lastTime).format('YYYY-MM-DD') : '',
              };
              const { lastFlat, lastPeak, lastTip, lastValley } = this.ruleForm2;

              this.ruleForm2.lastTotal = toFixed(
                Number(lastFlat) + Number(lastPeak) + Number(lastTip) + Number(lastValley),
                2
              );
              this.formData = res.propertyMeterSettleInfo;
              this.formData.orderTo = res.orderTo;
              this.formData.eleWay = res.eleWay;
            })
            .catch(() => {});
        }
      },
      // 结算方式
      settleWayChange(value) {
        this.settleWay = value;
      },
      handleRemove(file) {
        this[`ruleForm${this.meterSettleWay}`].settleAttachmentDTO = this[
          `ruleForm${this.meterSettleWay}`
        ].settleAttachmentDTO.filter(item => item.uid !== file.uid);
      },
      uploadAd(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this[`ruleForm${this.meterSettleWay}`].settleAttachmentDTO.push({
              url: res,
              uid: file.uid,
              fileName: file.name,
            });
            this.$refs[`ruleForm${this.meterSettleWay}`].validateField('settleAttachmentDTO');
          });
        }
      },
      calcCurrent(type) {
        if (this.meterSettleWay === 1) {
          this.ruleForm1[type] = toFixed(
            this.ruleForm1[`local${type}`] - this.ruleForm1[`last${type}`],
            2
          );
        } else {
          this.ruleForm2[type] = toFixed(
            this.ruleForm2[`local${type}`] - this.ruleForm2[`last${type}`],
            2
          );
          const {
            localFlat,
            lastFlat,
            localPeak,
            lastPeak,
            localTip,
            lastTip,
            localValley,
            lastValley,
          } = this.ruleForm2;
          this.ruleForm2.localTotal = toFixed(
            (localFlat * 100 + localPeak * 100 + localTip * 100 + localValley * 100) / 100,
            2
          );
          this.ruleForm2.lastTotal = toFixed(
            (lastFlat * 100 + lastPeak * 100 + lastTip * 100 + lastValley * 100) / 100,
            2
          );
          this.ruleForm2.Total = toFixed(this.ruleForm2.localTotal - this.ruleForm2.lastTotal, 2);
        }
      },

      submit() {
        const form = this.meterSettleWay === 1 ? 'ruleForm1' : 'ruleForm2';
        this.$refs[form].validate(valid => {
          if (valid) {
            this.loading = true;
            if (this.meterSettleWay === 1) {
              propertySettleManage
                .addOne({
                  meterWay: this.meterSettleWay,
                  localMeterKwh: Number(this.ruleForm1.localMeterKwh),
                  lastMeterKwh: Number(this.ruleForm1.lastMeterKwh),
                  endSettleDate: `${this.ruleForm1.endSettleDate} 23:59:59`,
                  settleAttachmentDTO: this.ruleForm1.settleAttachmentDTO,
                  stationId: this.stationId,
                  remark: this.ruleForm1.remark,
                  startSettleDate: `${this.ruleForm1.lastTime} 00:00:00`,
                })
                .then(res => {
                  this.loading = false;
                  this.$router.go(-1);
                })
                .catch(() => {
                  this.loading = false;
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              propertySettleManage
                .addOne({
                  meterWay: this.meterSettleWay,
                  startSettleDate: `${this.ruleForm2.lastTime} 00:00:00`,
                  endSettleDate: `${this.ruleForm2.endSettleDate} 23:59:59`,
                  settleAttachmentDTO: this.ruleForm2.settleAttachmentDTO,
                  stationId: this.stationId,
                  remark: this.ruleForm2.remark,
                  localMeterKwh: this.ruleForm2.localTotal,
                  peaksValleyDTO: {
                    localFlat: Number(this.ruleForm2.localFlat),
                    lastFlat: Number(this.ruleForm2.lastFlat),
                    localPeak: Number(this.ruleForm2.localPeak),
                    lastPeak: Number(this.ruleForm2.lastPeak),
                    localTip: Number(this.ruleForm2.localTip),
                    lastTip: Number(this.ruleForm2.lastTip),
                    localValley: Number(this.ruleForm2.localValley),
                    lastValley: Number(this.ruleForm2.lastValley),
                  },
                })
                .then(res => {
                  this.loading = false;
                  this.$router.go(-1);
                })
                .catch(() => {
                  this.loading = false;
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .ykc-form-item.imgUpload .el-form-item .el-form-item__content .el-form-item__error {
    left: 0;
    right: auto;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      border-radius: 0;
      margin: 10px 0px 0px 0px;
      border: none;
      .btn-wrap {
        margin-bottom: 10px;
      }
      ::v-deep .el-card__header {
        border: 0;
        padding: 10px;
        height: auto;
        box-sizing: border-box;

        @media screen and (max-width: 1366px) {
          height: auto;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .flexImg {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
      .YkcForms1 {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        ::v-deep .ykc-form-item {
          width: 50%;
          .el-form-item {
            display: flex;

            .el-form-item__label {
              line-height: 30px;
              padding: 0 8px 0 0;
              font-size: 12px;
              width: 174px;
            }

            .el-form-item__content {
              flex: 1;
            }
          }
        }
      }
      .YkcForms2 {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        ::v-deep .ykc-form-item {
          width: 33.33%;
          .el-form-item {
            display: flex;

            .el-form-item__label {
              line-height: 30px;
              padding: 0 8px 0 0;
              font-size: 12px;
              width: 200px;
            }

            .el-form-item__content {
              flex: 1;
            }
          }
        }
      }
    }
  }
</style>
