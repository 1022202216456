<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card" :labelWidth="70">
        <div slot="header" class="clearfix" style="height: auto">
          <div class="btn-wrap">
            <el-button
              size="small"
              class="confirm"
              :loading="loading"
              type="primary"
              @click="submit">
              提交申请
            </el-button>
          </div>

          <span>基本信息</span>
        </div>
        <div class="flexBox">
          <ykc-form
            ref="ruleForm"
            class="YkcForms"
            :model="ruleForm"
            :rules="rules"
            labelPosition="right">
            <ykc-form-item
              label="结算时间"
              prop="settleDate"
              style="width: 380px; margin-bottom: 0">
              <el-date-picker
                v-model="ruleForm.settleDate"
                type="monthrange"
                range-separator="至"
                @change="timeChange"
                start-placeholder="结算开始月份"
                end-placeholder="结算结束月份"
                :picker-options="pickerOptions"
                format="yyyy-MM"
                value-format="yyyy-MM"></el-date-picker>
            </ykc-form-item>
          </ykc-form>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="结算方式">
            <span>{{ dealData(formData.settleWay) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="站点编码">
            <span>{{ dealData(formData.stationCode) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="站点名称">
            <span>{{ dealData(formData.stationName) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="物业">
            <span>{{ dealData(formData.propertyName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="是否过流水">
            <span>{{ formData.orderTo === 1 ? '是' : '否' }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="取电方式">
            <span>{{ dealData(formData.eleWay) }}</span>
          </ykc-detail-item-plus>
        </div>

        <div class="flexBox">
          <ykc-detail-item-plus label="结算总电量">
            <span>
              {{ dealData(formData.settleTotalEleCap) }}
            </span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="结算总金额">
            <span>{{ dealData(formData.settleTotalAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="服务费抽成">
            <span>{{ dealData(formData.serviceSharingPrice) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="服务费分成">
            <span>{{ dealData(formData.serviceSharingFee) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="备注">
            <ykc-input
              v-model="ruleForm.remark"
              type="textarea"
              placeholder="备注"
              maxlength="500"></ykc-input>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label=""></ykc-detail-item-plus>
        </div>
        <ykc-table
          ref="YkcTable"
          style="box-shadow: none"
          operateFixedType="right"
          :data="tableData"
          title="结算清单按月信息"
          :columns="tableColumns"
          :operateButtons="tableOperateButtons"></ykc-table>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import dayjs from 'dayjs';
  import { propertySettleManage } from '@/service/apis';
  // import { dealData } from '@/utils/index';

  export default {
    name: 'settle',
    components: {},
    data() {
      return {
        stationId: '',
        formData: {},
        disabledStartTime: '',
        disabledEndTime: '',
        loading: false,
        ruleForm: {
          remark: '',
          startTime: '',
          endTime: '',
          settleDate: [],
          // settleDate: [null, '2024-06'],
        },
        isFirst: true,
        rules: {
          settleDate: [
            { type: 'array', required: true, message: '请选择结算日期', trigger: 'blur' },
          ],
        },
        tableData: [],
        tableColumns: [
          {
            label: '年月',
            prop: 'date',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return dayjs(row.date).format('YYYY-MM');
              },
            },
          },
          { label: '电量', prop: 'eleCap', minWidth: '100px' },
          { label: '电费', prop: 'eleFee', minWidth: '100px' },
          { label: '服务费分成', prop: 'serviceFeeSharingAmount', minWidth: '100px' },
          { label: '总金额', prop: 'totalAmount', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportDetail(row);
                }}>
                导出明细
              </ykc-button>
            ),
          },
        ],
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },

      pickerOptions() {
        const that = this;
        return {
          disabledDate(date) {
            if (that.disabledStartTime) {
              return (
                (date && date.valueOf() > dayjs(that.disabledEndTime).valueOf()) ||
                date.valueOf() < dayjs(that.disabledStartTime).valueOf()
              );
            }
            return date && date.valueOf() > dayjs(that.disabledEndTime).valueOf();
          },
        };
      },
    },
    created() {
      const { stationId } = this.$route.query;

      this.stationId = stationId;
      this.queryDetail();
    },
    methods: {
      /**
       * 初始数据
       */

      initData() {
        this.tableOperateButtons = [];
      },

      dealData(value) {
        return value === null ? '——' : value;
      },

      queryDetail() {
        // 编辑回显
        if (this.stationId) {
          propertySettleManage
            .getSettleInfo({
              stationId: this.stationId,
              startTime: this.ruleForm.startTime,
              endTime: this.ruleForm.endTime,
            })
            .then(res => {
              this.formData = res.propertySysSettleInfo;
              this.formData.orderTo = res.orderTo;
              this.formData.eleWay = res.eleWay;

              const startTime = res.startTime ? dayjs(res.startTime).format('YYYY-MM') : '';
              const endTime = res.endTime ? dayjs(res.endTime).format('YYYY-MM') : '';
              this.ruleForm.startTime = res.startTime;
              this.ruleForm.endTime = res.endTime;
              if (startTime && endTime) {
                this.ruleForm.settleDate = [startTime, endTime];
              }

              if (this.isFirst) {
                this.disabledEndTime = endTime;
                this.disabledStartTime = startTime;
                this.isFirst = false;
              }
              this.tableData = res.propertySysSettleInfo.settleDetail || [];
            })
            .catch(() => {});
        }
      },
      timeChange(value) {
        this.ruleForm.startTime = dayjs(value[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        this.ruleForm.endTime = dayjs(value[1]).endOf('month').format('YYYY-MM-DD HH:mm:ss');

        this.queryDetail();
      },
      /**
       * @desc 导出明细
       * */
      exportDetail(row) {
        const { date } = row;
        const { stationId } = this;
        const month = dayjs(date).format('YYYY-MM');
        propertySettleManage.exportMonthDetail({ stationId, month }).then(res => {
          window.open(res, '_blank');
        });
      },
      submit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.loading = true;
            propertySettleManage
              .addOne({
                endSettleDate: this.ruleForm.endTime,
                stationId: this.stationId,
                remark: this.ruleForm.remark,
                startSettleDate: this.ruleForm.startTime,
              })
              .then(res => {
                this.loading = false;
                this.$router.go(-1);
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      border-radius: 0;
      margin: 10px 0px 0px 0px;
      border: none;
      .btn-wrap {
        margin-bottom: 10px;
      }
      ::v-deep .el-card__header {
        border: 0;
        padding: 10px;
        height: auto;
        box-sizing: border-box;

        @media screen and (max-width: 1366px) {
          height: auto;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .YkcForms {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        ::v-deep .ykc-form-item {
          width: 50%;
          .el-form-item {
            display: flex;

            .el-form-item__label {
              line-height: 30px;
              padding: 0 8px 0 0;
              font-size: 12px;
              width: 74px;
            }

            .el-form-item__content {
              flex: 1;
            }
          }
        }
      }
    }
  }
</style>
