<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="省市区" prop="addressId">
          <ykc-cascader
            :isObject="true"
            :options="addressData"
            v-model="ruleForm.addressId"></ykc-cascader>
        </ykc-form-item>
        <ykc-form-item label="建桩社区" prop="communityName">
          <ykc-input v-model="ruleForm.communityName" maxlength="50"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="物业" prop="propertyId">
          <ykc-dropdown :data="propertyData" v-model="ruleForm.propertyId"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="投资方" prop="investorId">
          <ykc-dropdown :data="investData" v-model="ruleForm.investorId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="建桩金额" prop="pileAmount">
          <ykc-input v-model="ruleForm.pileAmount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="桩功率" prop="pilePower">
          <ykc-input
            placeholder="请输入充电桩功率"
            type="Number"
            maxlength="30"
            v-model="ruleForm.pilePower">
            <template slot="append">KW</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="桩类型" prop="pileType">
          <ykc-dropdown
            :data="[
              { id: 1, name: '直流' },
              { id: 2, name: '交流' },
            ]"
            v-model="ruleForm.pileType"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item
          prop="image"
          label="宣传页图片"
          class="file-error img-error"
          bottomTip="温馨提示：建议上传宽度为360px的jpg、jepg、png文件">
          <div class="flex upload-img-box">
            <ykc-upload ref="adsImg" @handleChange="uploadAd"></ykc-upload>
          </div>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import {
    propertySettleManage,
    salePrivatePileApi,
    treeUtils,
    uploadUtils,
    investmentData,
  } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      gunId: {
        type: Number,
      },
      editInfo: {
        type: Object,
      },
      drawerType: {
        type: String,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        addressData: [], // 省市区数据
        propertyData: [], // 物业数据
        investData: [], // 投资方数据

        ruleForm: {
          id: '',
          addressId: '',
          provinceId: '',
          cityId: '',
          districtId: '',
          image: '',
          investorId: '',
          pileAmount: '',
          propertyId: '',
          communityName: '',
          pileType: '',
          pilePower: '',
        },
        rules: {
          addressId: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
          investorId: [{ required: true, message: '请选择投资方', trigger: 'blur' }],
          propertyId: [{ required: true, message: '请选择物业', trigger: 'blur' }],
          pileAmount: [
            { required: true, message: '请输入建桩金额', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                console.log(value);
                if (Number(value) > 10000 || Number(value) < 0.01) {
                  callback('请输入正确的建桩金额（范围：0.01~10000）');
                } else {
                  this.$refs.ruleForm.clearValidate('pileAmount');
                  callback();
                }
              },
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              trigger: 'blur',
              message: '请输入正确的建桩金额（范围：0.01~10000）',
            },
          ],
          pileType: [{ required: true, message: '请选择电桩类型', trigger: 'blur' }],
          pilePower: [
            { required: true, message: '请输入充电桩功率', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('桩功率', 99999999),
            },
          ],
          communityName: [{ required: true, message: '请输入建桩社区', trigger: 'blur' }],
          image: [{ required: true, message: '请上传宣传页图片', trigger: 'change' }],
        },
      };
    },
    created() {
      this.requestPropertyList();
      this.requestGetDistrict();
      this.requestInvestList();
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
          id: this.id, // 编辑需要传
        };
        return formData;
      },
    },
    methods: {
      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
            if (this.isProperty) {
              this.searchParams.propertyId = res[0].id;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取投资方列表
       */
      requestInvestList() {
        investmentData
          .investmentList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            const params = res.records;
            params.map(item => {
              return this.investData.push({
                id: item.id,
                name: item.investorName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      uploadAd(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.image = res;
          });
        }
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              const params = {
                ...this.formData,
                provinceId: this.formData.addressId[0],
                cityId: this.formData.addressId[1],
                districtId: this.formData.addressId[2],
              };
              delete params.addressId;
              if (this.drawerType === 'add') {
                salePrivatePileApi
                  .add(params)
                  .then(res => {
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              } else {
                salePrivatePileApi
                  .update(params)
                  .then(res => {
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              }
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>
