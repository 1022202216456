<!--
 * @Description: 私桩二维码列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        class="sale-pile-table"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'salePile:qrcode:list:add'" type="plain" @click="add">
              新增
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!-- 新增/编辑抽屉 -->
      <ykc-drawer
        :title="drawerTitle"
        :show.sync="showDrawer"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            v-if="drawerType === 'add' || drawerType === 'edit'"
            ref="addOrEdit"
            :drawerType="drawerType"
            :id="id"
            :isEdit="isEdit"
            :editInfo="editInfo"></AddOrEdit>
        </div>
      </ykc-drawer>

      <ykc-operation-dialog title="二维码" customerWidth="350px" :show.sync="showDialog">
        <div slot="content">
          <el-image style="width: 300px; height: 300px" :src="qrcodeImg"></el-image>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { salePrivatePileApi, propertySettleManage } from '@/service/apis';
  import { code } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';
  import { downloadOSSFile } from '@/utils/index';

  export default {
    name: 'privatePileQrcode',
    components: {
      AddOrEdit,
    },

    data() {
      return {
        gunId: '',
        editInfo: {},
        qrcodeImg: '',
        showDialog: false,

        drawerType: '',

        isEdit: false,
        showDrawer: false,

        propertyData: [], // 物业数据
        searchParams: {
          propertyId: null,
          communityName: '',
        },

        tableTitle: '私桩二维码',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '省市区', prop: 'pcd', minWidth: '200px' },
          {
            label: '图片',
            prop: 'image',
            minWidth: '100px',

            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div style="height:40px">
                    <el-image
                      preview-src-list={[row.image]}
                      style="width: 40px; height: 40px"
                      src={row.image}
                    />
                  </div>
                );
              },
            },
          },
          {
            label: '物业',
            prop: 'propertyName',
            minWidth: '250px',
          },
          {
            label: '建桩社区',
            prop: 'communityName',
            minWidth: '250px',
          },
          {
            label: '桩功率',
            prop: 'pilePower',
            minWidth: '100px',
          },
          {
            label: '桩类型',
            prop: 'pileType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return row.pileType === 1 ? '直流' : '交流';
              },
            },
          },
          {
            label: '投资方',
            prop: 'investorName',
            minWidth: '150px',
          },
          {
            label: '建桩金额',
            prop: 'pileAmount',
            minWidth: '150px',
          },
          { label: '日期', prop: 'date', minWidth: '200px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    created() {
      this.requestPropertyList();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcDropdown',
            key: 'propertyId',
            label: '物业',
            placeholder: '请选择物业',
            data: this.propertyData,
          },
          {
            comName: 'YkcInput',
            key: 'communityName',
            label: '建桩社区',
            placeholder: '请输入建桩社区',
          },
        ];
      },
      drawerTitle() {
        let txt = '';
        if (this.drawerType === 'add') {
          txt = `私桩二维码新增`;
        } else if (this.drawerType === 'edit') {
          txt = `私桩二维码编辑`;
        }
        return txt;
      },
    },
    methods: {
      code,
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          // {
          //   enabled: () => code('asset:station:privatepile:edit'),
          //   // enabled: () => true,
          //   render: (h, data, { row }) => (
          //     <ykc-button
          //       type="text"
          //       onClick={() => {
          //         this.id = row.id;
          //         this.editInfo = row;
          //         this.drawerType = 'edit';
          //         this.showDrawer = true;
          //       }}>
          //       编辑
          //     </ykc-button>
          //   ),
          // },

          {
            enabled: () => code('salePile:qrcode:list:del'),

            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doDelete(row);
                }}>
                删除
              </ykc-button>
            ),
          },
          {
            enabled: () => code('salePile:qrcode:list:code'),

            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.qrcode(row);
                }}>
                生成二维码
              </ykc-button>
            ),
          },
          {
            enabled: () => code('salePile:qrcode:list:exportCode'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportCode(row);
                }}>
                导出二维码
              </ykc-button>
            ),
          },
        ];
      },

      /**
       * 新增
       * */
      add() {
        this.id = null;
        this.drawerType = 'add';
        this.showDrawer = true;
      },

      /**
       * @desc 单个删除
       * */
      doDelete(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '是否确定删除？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            salePrivatePileApi
              .del({ id: row.id })
              .then(res => {
                console.log(res);
                this.$message.success('删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 导出二维码
       */
      async exportCode(row) {
        const url = await this.getCode(row);
        downloadOSSFile(url, row.communityName);
      },
      /**
       * 生成二维码
       */
      async qrcode(row) {
        const url = await this.getCode(row);
        this.qrcodeImg = url;
        this.showDialog = true;
      },
      async getCode(row) {
        const res = await salePrivatePileApi.code({ id: row.id });
        return res;
      },
      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            done(); // 关闭弹框
            drawerDone(); // 关闭抽屉
          },
        });
      },

      /**
       * @desc 关闭抽屉
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },

      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        salePrivatePileApi
          .list(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
</style>
<style lang="scss">
  .sale-pile-table.ykc-table {
    .el-table .el-table__body-wrapper .el-table__cell .cell,
    .el-table .el-table__fixed-body-wrapper .el-table__cell .cell {
      height: auto;
    }
  }
</style>
