<!--
 * @Description: 私桩二维码列表  
-->
<template>
  <scroll-layout class="salePileOrder-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        class="sale-pile-table"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :cellStyle="{ height: 'auto' }"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>

      <ykc-operation-dialog
        title="编辑"
        :before-confirm="changeCode"
        confirm-btn-txt="确定"
        :show.sync="showDialog">
        <div slot="content">
          <ykc-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <ykc-form-item label="设备编码" prop="pileCode">
              <ykc-input
                class="box-width box-width-left"
                maxlength="50"
                v-model="ruleForm.pileCode"></ykc-input>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { salePrivatePileApi, treeUtils } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'privatePileQrcode',
    components: {},

    data() {
      return {
        id: '',
        showDialog: false,

        ruleForm: {
          pileCode: '',
        },
        rules: {
          pileCode: [{ required: true, message: '请输入设备编码', trigger: 'blur' }],
        },

        addressData: [],
        searchParams: {
          phone: '',
          communityName: '',
          parkLot: '',
          addressId: [],
          checkDate: '',
          checked: '',
        },
        tableTitle: '私桩工单',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '用户手机号', prop: 'phone', minWidth: '150px' },
          {
            label: '建桩联系人',
            prop: 'contacts',
            minWidth: '150px',
          },
          {
            label: '省市区',
            prop: 'pcd',
            minWidth: '200px',
          },
          {
            label: '设备编码',
            prop: 'pileCode',
            minWidth: '150px',
          },
          {
            label: '建桩社区',
            prop: 'communityName',
            minWidth: '250px',
          },
          {
            label: '详细地址',
            prop: 'address',
            minWidth: '250px',
          },
          { label: '车位号', prop: 'parkLot', minWidth: '100px' },
          {
            label: '桩功率',
            prop: 'pilePower',
            minWidth: '100px',
          },
          {
            label: '桩类型',
            prop: 'pileType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return row.pileType === 1 ? '直流' : '交流';
              },
            },
          },
          {
            label: '验收图片',
            prop: 'checkImage',
            minWidth: '180px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div style="height:40px">
                    {row.checkImage?.map(item => {
                      return (
                        <el-image
                          preview-src-list={row.checkImage}
                          style="width: 40px; height: 40px;margin-right:10px"
                          src={item}
                        />
                      );
                    })}
                  </div>
                );
              },
            },
          },
          { label: '验收日期', prop: 'checkDate', minWidth: '150px' },
          { label: '验收备注', prop: 'checkRemark', minWidth: '200px' },
          {
            label: '是否通过验收',
            prop: 'checked',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                switch (row.checked) {
                  case 0:
                    return '否';
                  case 1:
                    return '是';
                  default:
                    return '——';
                }
              },
            },
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    created() {
      this.requestGetDistrict();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcCascader',
            key: 'addressId',
            label: '省市区',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
            clearable: true,
          },
          {
            comName: 'YkcInput',
            key: 'parkLot',
            label: '车位号',
            placeholder: '请输入车位号',
          },
          {
            comName: 'YkcInput',
            key: 'communityName',
            label: '建桩社区',
            placeholder: '请输入建桩社区',
          },
          {
            comName: 'YkcInput',
            key: 'phone',
            label: '用户手机号',
            placeholder: '请输入用户手机号',
          },
          {
            key: 'checkDate',
            type: 'date',
            label: '验收日期',
            comName: 'YkcDatePicker',
          },
          {
            comName: 'YkcDropdown',
            key: 'checked',
            label: '是否通过验收',
            placeholder: '请选择验是否通过验收',
            data: [
              { id: '0', name: '否' },
              { id: '1', name: '是' },
            ],
          },
        ];
      },
    },
    methods: {
      code,
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('salePile:order:list:dispatch'),

            render: (h, data, { row }) =>
              row.dispatchStatus ? (
                <ykc-button type="text" disabled>
                  已派单
                </ykc-button>
              ) : (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.dispatchWork(row);
                  }}>
                  派单
                </ykc-button>
              ),
          },
          {
            enabled: () => code('salePile:order:list:edit'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.id = row.id;
                  this.ruleForm.pileCode = row.pileCode;
                  this.showDialog = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
        ];
      },

      /**
       * @desc 派单
       * */
      dispatchWork(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '是否确定派单？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            salePrivatePileApi
              .dispatch({ id: row.id })
              .then(res => {
                console.log(res);
                this.$message.success('派单成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            done(); // 关闭弹框
            drawerDone(); // 关闭抽屉
          },
        });
      },

      /**
       * @desc 关闭抽屉
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;

        Object.assign(this.searchParams, searchData);
        if (this.searchParams.addressId?.length) {
          [this.searchParams.provinceId, this.searchParams.cityId, this.searchParams.districtId] =
            searchData.addressId;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      changeCode() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            salePrivatePileApi.updatePileCode({ ...this.ruleForm, id: this.id }).then(res => {
              this.$message.success('修改成功');
              this.showDialog = false;
              this.requestList();
            });
          }
        });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        salePrivatePileApi
          .workList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
</style>
<style lang="scss">
  .sale-pile-table.ykc-table {
    .el-table .el-table__body-wrapper .el-table__cell .cell,
    .el-table .el-table__fixed-body-wrapper .el-table__cell .cell {
      height: auto;
    }
  }
</style>
