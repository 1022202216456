<template>
  <div
    v-viewer="{ toolbar: true, navbar: false, title: false }"
    class="images clearfix"
    @ready="readys"
    @shown="shown">
    <slot></slot>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import { downloadOSSFile } from '@/utils/index';

  export default {
    name: 'Preview',
    props: {
      images: Array,
      default: [],
    },
    data() {
      return {};
    },

    mounted() {
      this.$nextTick(() => {});
    },

    methods: {
      readys() {
        jQuery(this.$el.viewer.viewer).attr('style', 'z-index:9999;');
        const toolbar = jQuery(this.$el.viewer.viewer).find('.viewer-toolbar');
        toolbar.attr('style', 'height:100px');
        const downLoadEle = jQuery(
          `<Button
              class="downLoadImg"
              data-viewer-action="downLoadImg"
              style="
              display:flex;
              align-items:center;
              justify-content:center;
              background-color:#4d8cfd;
              outline:none;
              border:none;
              color:white;
              width:100px;
              height:40px;
              cursor: pointer;
              position: absolute;
              left: 50%;
              ">
              下载到本地
              </Button>
  `
        );

        if (toolbar.find('downLoadImg').length !== 0) {
          return;
        }
        toolbar.html(downLoadEle);
        // toolbar.find('ul').append(downLoadEle);
      },

      shown() {
        const self = this;
        jQuery('.downLoadImg')
          .off()
          .on('click', () => {
            const src = jQuery('.viewer-canvas img').attr('src');
            // const name = jQuery('.viewer-canvas img').attr('name');
            const { name } = self.images.filter(item => item.url === src)[0];
            downloadOSSFile(src, name);
          });
      },
    },
  };
</script>

<style scoped></style>
