import { render, staticRenderFns } from "./viewer-download.vue?vue&type=template&id=3da6164f&scoped=true"
import script from "./viewer-download.vue?vue&type=script&lang=js"
export * from "./viewer-download.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da6164f",
  null
  
)

export default component.exports