<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        title="电站列表"
        :data="tableData"
        :columns="tableColumns"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="抽成费用策略"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-left: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit :currentRow="currentRow" ref="addOrEdit"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import AddOrEdit from './AddOrEdit.vue';
  import { offlineExport, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { privatePileShareApi } from '@/service/apis';

  export default {
    name: 'RoyaltyStrategyList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        operateType: '',
        currentRow: {},
        selectedRows: [],
        showDrawer: false,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit &&
                this.$refs.addOrEdit.submitForm(() => {
                  this.showDrawer = false;
                  this.searchTableList();
                });
            },
          },
        ],
        tableData: [],

        tableOperateButtons: [
          {
            enabled: () => code('pileShare:share:fee:edit'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row);
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          {
            label: '建桩联系人',
            prop: 'pileName',
            minWidth: '200px',
          },
          {
            label: '电站名称',
            prop: 'stationName',
            minWidth: '200px',
          },
          { label: '终端编码', prop: 'gunCode', minWidth: '200px' },
          {
            label: '建桩联系人手机号',
            prop: 'pilePhone',
            minWidth: '200px',
          },
          {
            label: '分成内容',
            prop: 'splitType',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                const splitType = row.splitType === 1 ? '度数' : '折扣';
                const unit = row.splitType === 1 ? '元/度' : '%';
                return `${splitType}:${row.number}${unit}`;
              },
            },
          },

          {
            label: '允许桩主设置电费区间（元）',
            prop: 'elecMax',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return `${row.elecMin}~${row.elecMax}`;
              },
            },
          },
          {
            label: '允许桩主设置服务费区间（元）',
            prop: 'serviceMax',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return `${row.serviceMin}~${row.serviceMax}`;
              },
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          gunCode: '',
          pileName: '',
          pilePhone: '',
          stationName: '',
        },
        OperatorListData: [], // 归属商户
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'pileName',
            label: '建桩联系人姓名',
            placeholder: '请输入建桩联系人姓名',
          },
          {
            comName: 'YkcInput',
            key: 'pilePhone',
            label: '建桩联系人手机号',
            placeholder: '请输入建桩联系人手机号',
          },
        ];
      },
    },
    created() {
      this.searchTableList();
    },
    methods: {
      doEdit(row) {
        this.operateType = 'single';
        this.currentRow = row;
        this.showDrawer = true;
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        privatePileShareApi.list(data).then(res => {
          console.log(res);
          this.tableData = res.records;
          this.pageInfo.total = res.total;
          this.pageInfo.pageIndex = res.current;
        });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 获取勾选id */
      selectionChange(selection) {
        this.selectedRows = [...selection];
      },
    },
  };
</script>

<style lang="scss" scoped></style>
