<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-14 11:50:50
 * @ Description: 营销管理>标签分组>用户分组>用户分组详情
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card" :labelWidth="140">
        <div slot="header" class="clearfix">
          <span>对账单信息</span>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="结算单号">
            <span>{{ dealData(formData.settleNo) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="结算时间">
            <span>{{ dealData(formData.settleDate) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="结算方式">
            <span>{{ dealData(formData.settleWayName) }}</span>
            <span v-if="formData.settleWay === 2">
              {{ formData.meterSettleWay === 1 ? '(一口价)' : '(尖峰平谷)' }}
            </span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="站点编码">
            <span>{{ dealData(formData.stationCode) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="站点名称">
            <span>{{ dealData(formData.stationName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="物业">
            <span>{{ dealData(formData.propertyName) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="订单金额是否给物业">
            <span>{{ formData.orderTo === 1 ? '是' : '否' }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="取电方式">
            <span>{{ dealData(formData.eleWay) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label=""></ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="settleMeterPriceDTO">
          <ykc-detail-item-plus label="本次抄表度数">
            <span>{{ dealData(settleMeterPriceDTO.localKwh) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="上次抄表度数">
            <span>{{ dealData(settleMeterPriceDTO.lastKwh) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="">
            <span></span>
          </ykc-detail-item-plus>
          <!-- <ykc-detail-item-plus label="本次结算电量(KWh)">
            <span>{{ dealData(settleMeterPriceDTO.localSettleKwh) }}</span>
          </ykc-detail-item-plus> -->
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="本次抄表尖时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.localTip) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="上次抄表尖时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.lastTip) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="本次结算尖时电量(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.subTip) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="本次抄表峰时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.localPeak) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="上次抄表峰时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.lastPeak) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="本次结算峰时电量(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.subPeak) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="本次抄表平时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.localFlat) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="上次抄表平时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.lastFlat) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="本次结算平时电量(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.subFlat) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="本次抄表谷时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.localValley) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="上次抄表谷时度数(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.lastValley) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="本次结算谷时电量(KWh)">
            <span>{{ dealData(meterPeaksValleyDTO.subValley) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="尖时结算金额">
            <span>{{ dealData(meterPeaksValleyDTO.tipAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="峰时结算金额">
            <span>{{ dealData(meterPeaksValleyDTO.peakAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="平时结算金额">
            <span>{{ dealData(meterPeaksValleyDTO.flatAmount) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterPeaksValleyDTO">
          <ykc-detail-item-plus label="谷时结算金额">
            <span>{{ dealData(meterPeaksValleyDTO.valleyAmount) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="结算总电量(KWH)">
            <span>{{ dealData(formData.settleTotalEleCap) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="总结算金额(元)">
            <span>{{ dealData(formData.settleTotalAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="电费金额(元)">
            <span>{{ dealData(formData.eleFee) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="服务费分成(元)">
            <span>{{ dealData(formData.serviceFeeSharing) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="服务费抽成">
            <span>{{ dealData(formData.serviceSharingAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="审批状态">
            <span>{{ dealData(formData.status) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="发票状态">
            <span>{{ dealData(formData.invoiceStatus) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="收款日期">
            <span>{{ dealData(formData.collectionDate) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="系统电量">
            <span>{{ dealData(formData.sysEleCap) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="审批备注">
            <span>{{ dealData(formData.auditRemark) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="申请备注">
            <span>{{ dealData(formData.remark) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label=""></ykc-detail-item-plus>
        </div>
        <ykc-detail-item-plus label="附件" v-if="formData.settleWay === 2">
          <viewerDownload
            :images="this.formData.attachments"
            v-if="this.formData?.attachments?.length > 0">
            <img
              class="pic-img"
              v-for="(src, index) in this.formData.attachments"
              :src="src.url"
              :name="src.fileName"
              :key="index" />
          </viewerDownload>
          <span v-else>——</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-detail-plus class="box-card" :labelWidth="110">
        <div slot="header" class="clearfix">
          <span>结算信息</span>
        </div>
        <div class="flexBox" v-if="meterStatDTO">
          <ykc-detail-item-plus label="结算电量差值(KWH)">
            <span>{{ dealData(meterStatDTO.subTotalEleCap) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="系统电量(KWH)">
            <span>{{ dealData(meterStatDTO.sysEleCap) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="占比(%)">
            <span>{{ dealData(meterStatDTO.eleRatio) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox" v-if="meterStatDTO">
          <ykc-detail-item-plus label="总结算金额差值(元)">
            <span>{{ dealData(meterStatDTO.subSettleAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="系统结算金额(元)">
            <span>{{ dealData(meterStatDTO.sysSettleAmount) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="占比(%)">
            <span>{{ dealData(meterStatDTO.settleRatio) }}</span>
          </ykc-detail-item-plus>
        </div>
        <ykc-table
          ref="YkcTable"
          style="box-shadow: none"
          operateFixedType="right"
          :data="tableData"
          :columns="tableColumns"></ykc-table>
        <!-- :operateButtons="tableOperateButtons.filter(item => item.enabled())" -->
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import dayjs from 'dayjs';
  import { propertySettleManage } from '@/service/apis';
  import viewerDownload from '@/components/viewer-download.vue';

  export default {
    name: 'PropertymentDetail',
    components: {
      viewerDownload,
    },

    data() {
      return {
        id: '',
        formData: {},
        settleMeterPriceDTO: null,
        meterPeaksValleyDTO: null,
        meterStatDTO: null,
        pageButtons: [
          {
            id: '0',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableData: [],
        tableColumns: [
          {
            label: '年月',
            prop: 'date',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return dayjs(row.date).format('YYYY-MM');
              },
            },
          },
          { label: '电量', prop: 'eleCap', minWidth: '100px' },
          { label: '电费金额', prop: 'eleFee', minWidth: '100px' },
          { label: '服务费分成金额', prop: 'serviceFeeSharingAmount', minWidth: '100px' },
          { label: '总结算金额', prop: 'totalAmount', minWidth: '100px' },
        ],
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.queryDetail();
    },
    methods: {
      dealData(value) {
        return value === null ? '——' : value;
      },
      queryDetail() {
        // 编辑回显
        if (this.id) {
          propertySettleManage
            .getOne({ id: this.id })
            .then(res => {
              this.formData = res;
              this.formData.orderTo = res.orderTo;
              this.formData.attachments.forEach(element => {
                element.name = element.fileName;
              });
              this.settleMeterPriceDTO = res.settleMeterPriceDTO;
              this.meterPeaksValleyDTO = res.meterPeaksValleyDTO;
              this.meterStatDTO = res.meterStatDTO;
              this.tableData = res.settleItemVOList || [];
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      border: none;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .pic-img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 20px;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
