<!--
 * @Description: 私桩二维码列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>

      <ykc-operation-dialog
        title="选择建桩社区"
        :before-confirm="changeCommunity"
        confirm-btn-txt="确定"
        :show.sync="showDialog">
        <div slot="content">
          <ykc-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <ykc-form-item label="建桩社区" prop="pileQrCodeId">
              <ykc-dropdown
                class="box-width box-width-left"
                :data="communityListData"
                v-model="ruleForm.pileQrCodeId"></ykc-dropdown>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
      <ykc-operation-dialog
        title="支付方式"
        :before-confirm="changePayway"
        confirm-btn-txt="确定"
        :show.sync="showPayDialog">
        <div slot="content">
          <ykc-form ref="payForm" :model="payForm" :rules="payRules">
            <ykc-form-item label="支付方式" prop="payWay">
              <ykc-dropdown
                class="box-width box-width-left"
                :data="paywayData"
                v-model="payForm.payWay"></ykc-dropdown>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog';
  import { salePrivatePileApi, propertySettleManage, treeUtils } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'privatePileQrcode',
    components: { YkcOperationDialog },

    data() {
      return {
        gunId: '',
        editInfo: {},
        showDialog: false,
        showPayDialog: false,
        drawerType: '',
        isEdit: false,
        showDrawer: false,
        addressData: [],
        propertyData: [], // 物业数据
        communityListData: [], // 建桩社区数据
        ruleForm: {
          pileQrCodeId: '',
        },
        rules: {
          pileQrCodeId: [{ required: true, message: '请选择建桩社区', trigger: 'blur' }],
        },
        payForm: {
          payWay: '',
        },
        payRules: {
          payWay: [{ required: true, message: '请选择支付方式', trigger: 'blur' }],
        },
        searchParams: {
          contacts: '',
          communityName: '',
          parkLot: '',
          address: '',
          phone: '',
          cityId: '',
          provinceId: '',
          districtId: '',
          addressId: [],
        },

        tableTitle: '私桩审核',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        paywayData: [
          { name: '微信支付', id: '1' },
          { name: '支付宝支付', id: '2' },
          { name: '现金支付', id: '3' },
        ],
        tableColumns: [
          { label: '用户手机号', prop: 'phone', minWidth: '150px', id: 0 },
          {
            label: '建桩联系人',
            prop: 'contacts',
            minWidth: '150px',
          },
          {
            label: '身份证号',
            prop: 'cardNo',
            minWidth: '200px',
          },
          { label: '省市区', prop: 'pcd', minWidth: '250px' },
          {
            label: '建桩社区',
            prop: 'communityName',
            minWidth: '250px',
          },

          {
            label: '详细地址',
            prop: 'address',
            minWidth: '250px',
          },
          { label: '车位号', prop: 'parkLot', minWidth: '100px' },
          {
            label: '桩功率',
            prop: 'pilePower',
            minWidth: '100px',
          },
          {
            label: '桩类型',
            prop: 'pileType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return row.pileType === 1 ? '直流' : '交流';
              },
            },
          },
          { label: '订单金额', prop: 'orderAmount', minWidth: '100px' },
          {
            label: '是否到账',
            prop: 'isPay',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return row.isPay ? '是' : '否';
              },
            },
          },
          {
            label: '审核状态',
            prop: 'status',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return this.statusData(row.status).name;
              },
            },
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    created() {
      this.requestPropertyList();
      this.requestGetDistrict();
      this.requestCommunityList();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'contacts',
            label: '建桩联系人',
            placeholder: '请输入建桩联系人',
          },
          {
            comName: 'YkcInput',
            key: 'phone',
            label: '用户手机号',
            placeholder: '请输入用户手机号',
          },

          {
            comName: 'YkcCascader',
            key: 'addressId',
            label: '省市区',
            clearable: true,
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
          },
          {
            comName: 'YkcInput',
            key: 'communityName',
            label: '建桩社区',
            placeholder: '请输入建桩社区',
          },
          {
            comName: 'YkcInput',
            key: 'address',
            label: '详细地址',
            placeholder: '请输入详细地址',
          },
          {
            comName: 'YkcInput',
            key: 'parkLot',
            label: '车位号',
            placeholder: '请输入车位号',
          },
        ];
      },
    },
    methods: {
      code,
      /**
       * 审核状态
       * */
      statusData(status) {
        let sta = {
          name: '',
          code: 1,
        };
        if (status === 3 || status === 5 || status === 6 || status === 7) {
          sta = {
            name: '已审核',
            code: 1,
          };
        } else if (status === 1 || status === 2) {
          sta = {
            name: '待审核',
            code: 2,
          };
        } else if (status === 4) {
          sta = {
            name: '审核拒绝',
            code: 3,
          };
        }
        return sta;
      },
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('salePile:examine:list:refuse'),

            render: (h, data, { row }) =>
              row.status < 3 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.handleStatus(row, 2);
                  }}>
                  拒绝
                </ykc-button>
              ) : (
                <ykc-button type="text" disabled>
                  拒绝
                </ykc-button>
              ),
          },
          {
            enabled: () => code('salePile:examine:list:approval'),

            render: (h, data, { row }) =>
              row.isPay === 1 && row.status < 3 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.handleStatus(row, 1);
                  }}>
                  通过
                </ykc-button>
              ) : (
                <ykc-button type="text" disabled>
                  通过
                </ykc-button>
              ),
          },

          {
            enabled: () => code('salePile:examine:list:pay'),
            // enabled: () => true,
            render: (h, data, { row }) =>
              row.isPay === 1 || this.statusData(row.status).code === 1 ? (
                <ykc-button type="text" disabled>
                  线下支付
                </ykc-button>
              ) : (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.id = row.id;
                    this.showPayDialog = true;
                  }}>
                  线下支付
                </ykc-button>
              ),
          },
          {
            enabled: () => code('salePile:examine:list:del'),
            // enabled: () => true,
            render: (h, data, { row }) =>
              row.status === 4 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.doDelete(row);
                  }}>
                  删除
                </ykc-button>
              ) : (
                <ykc-button type="text" disabled>
                  删除
                </ykc-button>
              ),
          },
        ];
      },

      /**
       * 新增
       * */
      add() {
        this.id = null;
        this.drawerType = 'add';
        this.showDrawer = true;
      },

      /**
       * @desc 单个删除
       * */
      doDelete(row) {
        YkcDialog({
          title: '温馨提示',
          showFooter: true,
          dialogType: 'operation',
          size: 'medium',
          widget: {
            render() {
              return (
                <div>
                  <h3>是否删除此订单？</h3>
                  <p>删除订单后无法恢复，请与用户确认是否为用户办理退款。</p>
                </div>
              );
            },
          },
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            salePrivatePileApi
              .deletedPrivatePile({ id: row.id })
              .then(res => {
                console.log(res);
                this.$message.success('删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个修改状态
       * */
      handleStatus(row, type) {
        const txt = type === 1 ? '通过' : '拒绝';
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否确定${txt}？`,
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            salePrivatePileApi
              .approval({ opType: type, id: row.id })
              .then(res => {
                console.log(res);
                this.$message.success('提交成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            done(); // 关闭弹框
            drawerDone(); // 关闭抽屉
          },
        });
      },

      /**
       * @desc 关闭抽屉
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;

        Object.assign(this.searchParams, searchData);
        if (this.searchParams.addressId?.length) {
          [this.searchParams.provinceId, this.searchParams.cityId, this.searchParams.districtId] =
            searchData.addressId;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },

      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      requestCommunityList() {
        salePrivatePileApi.communityList().then(res => {
          this.communityListData = (res || []).map(item => {
            return {
              id: item.id,
              name: item.communityName,
            };
          });
        });
      },
      changePayway() {
        this.$refs.payForm.validate(valid => {
          if (valid) {
            salePrivatePileApi.approval({ ...this.payForm, id: this.id, opType: 3 }).then(res => {
              this.$message.success('修改成功');
              this.showPayDialog = false;
              this.requestList();
            });
          }
        });
      },
      changeCommunity() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            salePrivatePileApi.updatePileCode({ ...this.ruleForm, id: this.id }).then(res => {
              this.$message.success('修改成功');
              this.showDialog = false;
              this.requestList();
            });
          }
        });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        salePrivatePileApi
          .approvalList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }
        .cell .communityNameWrap {
          cursor: pointer;
          color: #4d8cfd;
          display: flex;
          align-items: center;
        }
        .cell .communityName {
          max-width: 190px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
</style>
