<!--
/**
 *@author zhengcc
 *@date 2024-06-11
 财务管理 对账管理 分成信息
*/
-->
<template>
  <custom-list
    table-title="分成信息"
    search-btn-text="查询"
    reset-btn-text="清空"
    :show-header="true"
    :request-call="true"
    :search-data="searchData"
    :search-params="searchParam"
    :table-columns="tableColumns"
    :request-method="privatePileShareApi.infoList"
    :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
    @query-form="handleSearchConfirm"
    @clear-form="handleSearchClean" />
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { privatePileShareApi } from '@/service/apis';
  import { getLastTime, getLastYear } from '@/utils';

  export default {
    name: 'CustomerDividedIntoInformationList',
    components: { CustomList },
    data() {
      return {
        privatePileShareApi,
        tableData: [],
        tableColumns: [
          { label: '建桩联系人', prop: 'pileName', minWidth: '160px' },
          { label: '建桩联系人手机号', prop: 'pilePhone', minWidth: '160px' },
          { label: '终端编码', prop: 'gunCode', minWidth: '200px' },
          {
            label: '分成日期区间',
            prop: 'startTime',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return `${row.startTime}~${row.endTime}`;
              },
            },
          },

          { label: '按折扣分成金额到桩主', prop: 'discountToPile', minWidth: '200px' },
          { label: '按折扣分成金额到平台', prop: 'discountToPlatform', minWidth: '200px' },
          { label: '按度数分成金额到桩主', prop: 'degreesToPile', minWidth: '200px' },
          { label: '按度数分成金额到平台', prop: 'degreesToPlatform', minWidth: '200px' },
          { label: '充电次数', prop: 'num', minWidth: '100px' },
          { label: '充电时长（分）', prop: 'chargedTime', minWidth: '100px' },
          {
            label: '电量（度）',
            prop: 'chargedPower',
            minWidth: '150px',
          },
          { label: '充电总金额（元）', prop: 'amount', minWidth: '150px' },
          { label: '服务费（元）', prop: 'serviceAmount', minWidth: '100px' },
        ],
        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },

        searchParam: {
          date: [],
          pileName: '',
          pilePhone: '',
          gunCode: '',
          startTime: '',
          endTime: '',
        },
      };
    },
    computed: {
      // dividedIntoInformationsApi() {
      //   return dividedIntoInformationsApi;
      // },
      searchData() {
        return [
          {
            key: 'date',
            label: '分成日期区间',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            key: 'pileName',
            label: '建桩联系人',
            comName: 'YkcInput',
            placeholder: '请输入建桩联系人',
          },
          {
            key: 'pilePhone',
            label: '建桩联系人手机号',
            comName: 'YkcInput',
            placeholder: '请输入建桩联系人手机号',
          },
          {
            key: 'gunCode',
            label: '终端编码',
            comName: 'YkcInput',
            placeholder: '请输入终端编码',
          },
        ];
      },
    },
    created() {
      const curDate = new Date().getDate() - 1;
      const startDate = getLastTime('yyyy-MM-dd', curDate);
      const endDate = getLastTime('yyyy-MM-dd', 0);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.startTime = `${startDate} 00:00:00`;
      this.searchParam.endTime = `${endDate} 23:59:59`;
    },
    methods: {
      /**
       * 切换日月
       */
      handleChange(obj) {
        const { select, event } = obj;
        console.log('obj+++', obj);
        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(6, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          // this.searchParam.billDateType = 2;
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const curDate = new Date().getDate() - 1;

          const startDateStr = getLastTime('yyyy-MM-dd', curDate);
          const endDateStr = getLastTime('yyyy-MM-dd', 0);

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = `${startDateStr} 00:00:00`;
          this.searchParam.endTime = `${endDateStr} 23:59:59`;
          // this.searchParam.billDateType = 1;
        }
      },

      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData+++', searchData);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParam, searchData);
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          // [this.searchParam.startTime, this.searchParam.endTime] = searchData.date;
          this.searchParam.startTime = `${searchData.date[0]} 00:00:00`;
          this.searchParam.endTime = `${searchData.date[1]} 23:59:59`;
        }
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);
        // 切换成日，默认状态
        const curDate = new Date().getDate() - 1;
        const startDate = getLastTime('yyyy-MM-dd', curDate);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.startTime = `${startDate} 00:00:00`;
        this.searchParam.endTime = `${endDate} 23:59:59`;
        // this.searchParam.isDiscount = 1;
        // this.searchParam.billDateType = 1;
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
