<!--
 * @Description: 物业统计列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtonsSplitCount="5"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'property:settle:list:apply'" type="plain" @click="apply">
              对账申请
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>

      <ykc-operation-dialog
        title="选择站点"
        :before-confirm="stationSelect"
        confirm-btn-txt="确定"
        :show.sync="showStationSelect">
        <div slot="content">
          <ykc-form ref="stationForm" :model="stationForm" :rules="stationRules">
            <ykc-form-item label="站点" prop="stationId">
              <ykc-dropdown
                class="box-width box-width-left"
                :data="stationListData"
                v-model="stationForm.stationId"></ykc-dropdown>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
      <ykc-operation-dialog
        title="审批结算单"
        :before-confirm="() => auditSettle(3)"
        :before-cancel="() => auditSettle(2)"
        confirm-btn-txt="审批通过"
        cancel-btn-txt="审批不通过"
        type="report"
        :show.sync="showAudit">
        <div slot="content">
          <ykc-form ref="auditForm" :model="auditForm" :rules="auditRules">
            <ykc-form-item label="审批备注" prop="auditRemark">
              <ykc-input
                v-model="auditForm.auditRemark"
                type="textarea"
                placeholder="备注"
                maxlength="500"></ykc-input>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog';
  import { propertySettleManage } from '@/service/apis';
  import { sessionGetItem, code } from '@/utils';

  export default {
    name: 'PropertySettleList',
    components: {
      YkcOperationDialog,
    },
    data() {
      return {
        id: '',
        isProperty: false,
        isEdit: false,
        showDrawer: false,
        showStationSelect: false,
        showAudit: false,
        drawerType: 'edit',
        stationId: '',
        dictionary: {},
        stationListData: [], // 电站列表
        propertyData: [], // 物业
        stationForm: {
          stationId: '',
        },
        auditForm: {
          auditRemark: '',
        },
        stationRules: {
          stationId: [{ required: true, message: '请选择站点', trigger: 'blur' }],
        },
        auditRules: {
          auditRemark: [{ required: true, message: '请输入审批备注', trigger: 'blur' }],
        },

        statusData: [
          { id: 1, name: '申请中' },
          { id: 2, name: '审批未通过' },
          { id: 3, name: '审批通过' },
          { id: 4, name: '已收款' },
        ],
        orderToData: [
          { id: 1, name: '是' },
          { id: 0, name: '否' },
        ],
        searchParams: {
          stationId: '',
          propertyId: '',
          startDate: '',
          endDate: '',
          status: '',
          orderTo: '',
        },
        // 列表表格
        tableTitle: '结算单列表',
        tableData: [],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('property:settle:list:audit') && row.status === 1 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.id = row.id;
                    this.showAudit = true;
                  }}>
                  审批结算单
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('property:settle:list:invoice') &&
              row.status === 3 &&
              row.invoiceStatus === 1 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.invoice(row.id);
                  }}>
                  确认发票
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('property:settle:list:pay') && row.status === 3 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.payment(row.id);
                  }}>
                  确认收款
                </ykc-button>
              ) : null,
          },
          {
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportFile(row.id);
                }}>
                导出对账单
              </ykc-button>
            ),
          },
          {
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportDetail(row.id);
                }}>
                导出明细
              </ykc-button>
            ),
          },
          {
            enabled: () => code('property:settle:list:detail'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.toDetail(row.id);
                }}>
                详情
              </ykc-button>
            ),
          },
        ], // 列表操作
        tableColumns: [
          { label: '结算单编号', prop: 'settleNo', minWidth: '200px' },
          {
            label: '结算时间',
            prop: 'settleTime',
            minWidth: '200px',
          },
          {
            label: '站点编码',
            prop: 'stationCode',
            minWidth: '200px',
          },
          { label: '站点名称', prop: 'stationName', minWidth: '200px' },
          // {
          //   label: '⼩区名称',
          //   prop: 'pileType',
          //   minWidth: '100px',
          //   scopedSlots: {
          //     default: ({ row }) => {
          //       const title = row.pileType === 1 ? '直流' : '交流';
          //       return <span title={title}>{title}</span>;
          //     },
          //   },
          // },
          { label: '物业名称', prop: 'propertyName', minWidth: '200px' },
          {
            label: '结算⽅式',
            prop: 'settleWay',
            minWidth: '150px',
          },
          {
            label: '订单金额是否给物业',
            prop: 'orderTo',
            minWidth: '150px',
          },
          {
            label: '服务费结算⽅式',
            prop: 'serviceWay',
            minWidth: '100px',
          },
          {
            label: '取电方式',
            prop: 'eleWay',
            minWidth: '100px',
          },
          {
            label: '服务费分成',
            prop: 'serviceSharing',
            minWidth: '200px',
          },
          {
            label: '结算总电量',
            prop: 'settleTotalEleCap',
            minWidth: '200px',
          },
          {
            label: '对账电费⾦额',
            prop: 'reconciliationEleAmount',
            minWidth: '200px',
          },
          {
            label: '服务费分成⾦额',
            prop: 'serviceSharingAmount',
            minWidth: '200px',
          },
          {
            label: '总结算⾦额',
            prop: 'settleTotalAmount',
            minWidth: '200px',
          },
          {
            label: '审批状态',
            prop: 'statusName',
            minWidth: '200px',
          },
          {
            label: '发票状态',
            prop: 'invoiceStatusName',
            minWidth: '200px',
          },
          {
            label: '收款⽇期',
            prop: 'collectionDate',
            minWidth: '200px',
          },
          {
            label: '审核备注',
            prop: 'auditRemark',
            minWidth: '200px',
          },
          {
            label: '创建⼈',
            prop: 'createdBy',
            minWidth: '200px',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            minWidth: '200px',
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;

                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDropdown',
            key: 'propertyId',
            label: '物业名称',
            placeholder: '请选择物业名称',
            data: this.propertyData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '站点名称',
            placeholder: '请选择站点名称',
            remote: true,
            data: this.stationListData,
          },
          // {
          //   comName: 'YkcInput',
          //   label: '⼩区名称',
          //   key: 'addressName',
          //   placeholder: '请输入⼩区名称',
          // },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '状态',
            placeholder: '请选择状态',
            data: this.statusData,
          },
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '创建时间',
            placeholder: '请选择创建时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'orderTo',
            label: '订单金额是否给物业',
            placeholder: '请选择状态',
            data: this.orderToData,
          },
        ];
      },
    },
    // mounted() {
    //   this.requestList();
    // },
    watch: {
      $route: {
        handler(_, from) {
          if (from && from.path.includes('ApplyBy')) {
            this.requestList();
          }
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.isProperty = JSON.parse(sessionGetItem('propertyFlag'));
      if (this.isProperty) {
        this.searchParams.propertyId = JSON.parse(sessionGetItem('propertyId'));
      }
    },
    mounted() {
      this.requestPropertyList();
      this.requestStationList();
      this.requestList();
      // this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('property:settle:list:audit'),
            render: (h, data, { row }) =>
              row.status === 1 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.id = row.id;
                    this.showAudit = true;
                  }}>
                  审批结算单
                </ykc-button>
              ) : null,
          },
          {
            enabled: () => code('property:settle:list:invoice'),
            render: (h, data, { row }) =>
              row.status === 2 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.invoice(row.id);
                  }}>
                  确认发票
                </ykc-button>
              ) : null,
          },
          {
            enabled: () => code('property:settle:list:pay'),
            render: (h, data, { row }) =>
              row.status === 2 ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.payment(row.id);
                  }}>
                  确认收款
                </ykc-button>
              ) : null,
          },
          {
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportFile(row.id);
                }}>
                导出对账单
              </ykc-button>
            ),
          },
          {
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.exportDetail(row.id);
                }}>
                导出明细
              </ykc-button>
            ),
          },
          {
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.toDetail(row.id);
                }}>
                详情
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 请求站点列表
       */
      requestStationList() {
        propertySettleManage
          .stationList({ keyword: '' })
          .then(res => {
            this.stationListData = (res || []).map(item => {
              return {
                id: item.stationId,
                name: item.name,
              };
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
            if (this.isProperty) {
              this.searchParams.propertyId = res[0].id;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 列表查询
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        propertySettleManage
          .queryList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 对账申请
       */
      apply() {
        this.stationForm.stationId = null;
        this.showStationSelect = true;
      },
      /**
       * 站点选择
       */
      stationSelect() {
        this.$refs.stationForm.validate(valid => {
          if (valid) {
            const { stationId } = this.stationForm;
            propertySettleManage.getSettleInfo({ stationId }).then(res => {
              this.showStationSelect = false;

              const { settleWay } = res;
              // 系统结算
              if (settleWay === 1) {
                this.$router.push({
                  path: '/propertyStatistics/propertySettlement/ApplyBySystem',
                  query: {
                    stationId: this.stationForm.stationId,
                  },
                });
              } else {
                // 抄表结算
                this.$router.push({
                  path: '/propertyStatistics/propertySettlement/ApplyByMeter',
                  query: {
                    stationId: this.stationForm.stationId,
                  },
                });
              }
              this.stationForm.stationId = null;
            });
          }
        });
      },

      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        if (searchData.date && searchData.date.length) {
          [this.searchParams.startDate, this.searchParams.endDate] = searchData.date;
        }
        Object.assign(this.searchParams, searchData);

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
          },
        });
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },

      toDetail(id) {
        this.$router.push({
          path: '/propertyStatistics/propertySettlement/Detail',
          query: {
            id,
          },
        });
      },
      /**
       * @desc 确认发票
       * */
      invoice(id) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '是否确认发票？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            propertySettleManage.confirmInvoice({ id }).then(res => {
              this.$message({
                message: '提交成功',
                type: 'success',
              });

              this.requestList();
              dialogDone();
            });
          },
        });
      },
      /**
       * @desc 确认收款
       * */
      payment(id) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '是否确认收款？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            propertySettleManage.confirmPayment({ id }).then(res => {
              this.$message({
                message: '提交成功',
                type: 'success',
              });

              this.requestList();
              dialogDone();
            });
          },
        });
      },
      /**
       * @desc 导出对账单
       * */
      exportFile(id) {
        propertySettleManage.exportStatement({ id }).then(res => {
          window.open(res, '_blank');
          this.requestList();
        });
      },
      /**
       * @desc 导出明细
       * */
      exportDetail(id) {
        propertySettleManage.exportDetail({ id }).then(res => {
          window.open(res, '_blank');
        });
      },
      /**
       * @desc 审核结算单
       * */
      auditSettle(status) {
        this.$refs.auditForm.validate(valid => {
          if (valid) {
            const { auditRemark } = this.auditForm;
            propertySettleManage.auditSettle({ id: this.id, status, auditRemark }).then(res => {
              this.$message({
                message: '提交成功',
                type: 'success',
              });
              this.auditForm.auditRemark = null;
              this.requestList();
              this.showAudit = false;
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
